/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@syncfusion/ej2-material-theme/styles/material.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// To show time picker over dialogs
.timepicker-overlay {
  z-index: 10000000 !important;
}

// To show time picker over dialogs
.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

[mattooltip] {
  // This makes scrolling possible on mobile when dragging fields.
  // matTooltip is the reason: https://github.com/angular/components/issues/4892
  touch-action: auto !important;
}

// Markdown styles for docs.
.markdown td {
  padding-left: 8px;
  padding-right: 8px;
}

.markdown th {
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid #e0e0e0;
}

.markdown-link {
  cursor: pointer;
}

span.katex-html {
  text-align: left;
  margin-left: 16px;
}

div.code-toolbar {
  position: relative;
  max-width: 900px;
}

div.toolbar {
  position: absolute;
  z-index: 10;
  top: 0.3em;
  right: 0.3em;
}

.copy-to-clipboard-button::before {
  content: "\f0c5";
  font-family: "Font Awesome 5 Free";
  margin-right: 5px;
  font-size: larger;
}
